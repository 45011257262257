<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">
    <v-row no-gutters class="mx-2">

      <!-- Btn -->
      <v-col cols="12" class="my-2">
        <v-row justify="end" no-gutters>
          <v-btn
              @click="toggleAddOrEditDialog(true)"
              class="primary"
          >
            {{ $t('common.material-forms.buttons.add') }}
          </v-btn>
        </v-row>
      </v-col>

      <!-- Details -->
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="pagination"
            :loading="loading"
            :items-per-page="20"
            sort-by="createdAt"
            :sort-desc="false"
            :footer-props="{'items-per-page-options': itemsPerPage}"
            class="elevation-1">

          <!-- Supplier -->
          <template v-slot:item.supplier="{item}">
            <router-link v-if="item.supplier" :to="{name: 'Supplier', params: {id: item.supplier.id}}">{{ item.supplier.name }}</router-link>
            <span v-else>{{ $t('common.misc.NA') }}</span>
          </template>

          <!-- Component -->
          <template v-slot:item.component="{item}">
            <router-link v-if="item.component" :to="{name: 'Component', params: {id: item.component.id}}">{{ item.component.name }}</router-link>
            <span v-else>{{ $t('common.misc.NA') }}</span>
          </template>

          <!-- Project impact -->
          <template v-slot:item.projectImpact="{item}">
            <router-link v-if="item.projectImpact" :to="{name: 'ProjectImpact', params: {id: item.projectImpact.id}}">{{ item.projectImpact.name }}</router-link>
            <span v-else>{{ $t('common.misc.NA') }}</span>
          </template>

          <!-- Type -->
          <template v-slot:item.type="{item}">
            <span>{{ item.type }}</span>
          </template>

          <!-- State -->
          <template v-slot:item.state="{item}">
            <span>{{ item.state }}</span>
          </template>

          <!-- Link supplier -->
          <template v-slot:item.link_supplier_form="{item}">
            <a target="_blank" :href="getLinkSupplierForm(item.supplier)">
              {{ getLinkSupplierForm(item.supplier) }}
            </a>
            <ButtonIconCopy :value="getLinkSupplierForm(item.supplier)" />
          </template>

          <!-- Link materials -->
          <template v-slot:item.link_material_form="{item}">
            <a target="_blank" :href="getLinkMaterialForm(item.supplier)">
              {{ getLinkMaterialForm(item.supplier) }}
            </a>
            <ButtonIconCopy :value="getLinkMaterialForm(item.supplier)" />
          </template>

          <!-- Actions -->
          <template v-slot:item.actions="{item}">

            <!-- Details btn -->
            <v-btn
              icon
              @click="toggleDetailsDialog(true, item)">
              <v-icon small>fas fa-eye</v-icon>
            </v-btn>

            <!-- Edit btn -->
            <v-btn
                icon
                @click="toggleAddOrEditDialog(true, item)">
              <v-icon small>fas fa-pencil</v-icon>
            </v-btn>

            <!-- Delete btn -->
            <v-btn
                icon
                :loading="currentItem && currentItem.id === item.id && currentItemLoadingBtn"
                @click="toggleDeleteDialog(true, item)">
              <v-icon small>fas fa-trash</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Details dialog -->
    <MaterialFormDetailsDialog
      v-if="showDialog.details"
      :value="showDialog.details"
      :material-form="currentItem"
      @close="toggleDetailsDialog(false, null)"
    />

    <!-- Add or Edit dialog -->
    <MaterialFormDialog
      v-if="showDialog.addOrEdit"
      :value="showDialog.addOrEdit"
      :item="currentItem"
      :supplier="supplier"
      :project-impact="projectImpact"
      :component="component"
      @close="(needRefresh) => toggleAddOrEditDialog(false, null, needRefresh)"
    />

    <!-- Delete dialog -->
    <DeleteDialog
        v-if="showDialog.delete"
        :value="showDialog.delete"
        :title="$t('suppliers.tabs.material-forms.delete.title')"
        @submit="deleteForm"
        @close="toggleDeleteDialog(false)"
    />
  </v-card>
</template>

<script>
import vuetifyDataTable from "@/mixins/vuetifyDataTable";

export default {
  name: "MaterialFormsTab",

  components: {
    MaterialFormDetailsDialog: () => import("@/components/Common/MaterialForms/MaterialFormDetailsDialog"),
    MaterialFormDialog: () => import("@/components/Common/MaterialForms/MaterialFormDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
    ButtonIconCopy: () => import("@/components/Common/Buttons/ButtonIconCopy"),
  },

  mixins: [vuetifyDataTable],

  props: {
    supplier: { type: Object, default: () => null },
    component: { type: Object, default: () => null },
    projectImpact: { type: Object, default: () => null }
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('common.material-forms.datatable.headers.supplier'),
          value: "supplier",
          sortable: false,
          width: "20%"
        },
        {
          text: this.$t('common.material-forms.datatable.headers.component'),
          value: "component",
          sortable: false,
          width: "20%"
        },
        {
          text: this.$t('common.material-forms.datatable.headers.projectImpact'),
          value: "projectImpact",
          sortable: false,
          width: "20%"
        },
        {
          text: this.$t('common.material-forms.datatable.headers.type'),
          value: "type",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t('common.material-forms.datatable.headers.state'),
          value: "state",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t('common.material-forms.datatable.headers.link_supplier_form'),
          value: "link_supplier_form",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t('common.material-forms.datatable.headers.link_material_form'),
          value: "link_material_form",
          sortable: false,
          width: "10%"
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "20%"
        },
      ],
      URL_API: {
        GET_ALL: `/material-forms`
      },
      filters: {
        projectsImpact: this.projectImpact ? [this.projectImpact.id] : [],
        suppliers: this.supplier ?  [this.supplier.id] : [],
        components: this.component ? [this.component.id] : []
      },
      showDialog: {
        details: false,
        addOrEdit: false,
        delete: false
      },

      currentItem: null,
      currentItemLoadingBtn: false
    }
  },

  methods: {
    toggleAddOrEditDialog(val, data = null, needRefresh = false) {
      this.currentItem = data;

      if (needRefresh)
        this.searchTrigger();

      this.showDialog.addOrEdit = val;
    },

    toggleDeleteDialog(val, data = null, needRefresh = false) {
      console.log(data);
      this.currentItem = data;
      if (needRefresh)
        this.searchTrigger();
      this.showDialog.delete = val;
    },

    toggleDetailsDialog(val, data = null) {
      this.currentItem = data;
      this.showDialog.details = val;
    },

    deleteForm() {
      this.currentItemLoadingBtn = true;
      this.$http
          .delete(`/material-forms/${this.currentItem.id}`, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.toggleDeleteDialog(false, null, true);
          })
          .catch((err) => {

          })
          .finally(() => {
            this.currentItemLoadingBtn = false;
          })
    },

    getLinkSupplierForm(supplier) {
      return `${process.env.VUE_APP_IMPACT_FORMS_URL}/suppliers/${supplier.ref}`
    },

    getLinkMaterialForm(supplier) {
      return `${process.env.VUE_APP_IMPACT_FORMS_URL}/suppliers/${supplier.ref}/materials`
    }
  }
}
</script>

<style scoped>

</style>
